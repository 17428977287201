<template>
    <div :class="[this.client.story.comingSoon ? 'comingSoon':'']" class="ClientStoriesThumbnail" v-if="this.show">
        <div class="ClientStoriesThumbnail-comingSoon" v-if="this.client.story.comingSoon"></div>
        <router-link :title="client.name" :to="`/client-stories/${this.client.key}`">
            <div class="ClientStoriesThumbnail-info">
                <figure :class="`ClientStoriesThumbnail-logo ClientStoriesThumbnail-logo_${this.client.key}`">
                    <img :alt="client.name" :src="`${this.publicPath}img/clients/${this.client.key}.png`" />
                </figure>
                <p class="ClientStoriesThumbnail-heading" v-html="client.story.heading"></p>

                <p class="ClientStoriesThumbnail-button" v-if="!this.client.story.comingSoon">Read the story</p>
                <p class="ClientStoriesThumbnail-button_comingSoon" v-else>Coming Soon</p>
            </div>
            <div class="ClientStoriesThumbnail-gradient"></div>
            <div :style="`background-image: url('${this.publicPath}img/clientsStories/Cover${this.client.key}.jpg')`" class="ClientStoriesThumbnail-cover"></div>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: 'ClientStoriesThumbnail',
        data() {
            return {
                publicPath: process.env.BASE_URL,
                show: this.$route.params.client !== this.client.key
            };
        },
        props: {
            client: {
                key: String,
                name: String,
                story: {
                    order: Number,
                    heading: String,
                    comingSoon: Boolean
                }
            }
        }
    };
</script>
